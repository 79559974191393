import Vue from "vue";
import Vuex from "vuex";
import message from "./message";
import device from "./device";
import user from "./user";
import patient from "./patient";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    message,
    device,
    user,
    patient,
  },
});
