import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/components/login/Login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/configuracion-de-dashboard",
    name: "setting-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "setting-dashboard" */
        "@/app/setting/SettingDashboard"
      ),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/dispositivos/:sede/:idBrand/:area/:id",
    name: "device",
    component: () =>
      import(
        /* webpackChunkName: "device" */
        "@/app/devices/Device"
      ),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/cama-pacientes/:sede/:area/:id",
    name: "patient",
    component: () =>
      import(
        /* webpackChunkName: "patient" */
        "@/app/patient/Patient"
      ),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/llamados-de-paciente",
    name: "call",
    component: () =>
      import(
        /* webpackChunkName: "call" */
        "@/app/alert/Call"
      ),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/dashboard-general",
    name: "dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */
        "@/app/dashboard/Dashboard"
      ),
    meta: {
      requireAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to,from,next) => {

  if(to.matched.some(route => route.meta.requireAuth)){
    if(localStorage.getItem('accessToken')) return next();
    return next('/login');
  }

  if (
    localStorage.getItem("authenticated") === "true" && 
    to.name === "login" || to.path === "/"
  ) {
    next('/configuracion-de-dashboard');
  } else {
    next();
  }

  next();
});

export default router;
