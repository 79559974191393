import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css"
import "vue-select/dist/vue-select.css"
import { ToastPlugin, ModalPlugin, BModal } from "bootstrap-vue";
import VueSmartWidget from "vue-smart-widget";
import VuePaginate from "vue-paginate";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuelidate from "vuelidate";
import RepositoryProvider from "@/providers/RepositoryProvider";
import VueApexCharts from "vue-apexcharts";
import Echo from "laravel-echo";
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.component("apexchart", VueApexCharts);
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin, {
	BModal: { noCloseOnBackdrop: true, noCloseOnEsc: true },
});

Vue.use(VueSmartWidget);
Vue.use(VuePaginate);
Vue.use(VueSweetalert2);

window.Pusher = require('pusher-js')
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_KEY_REAL_TIME,
  // key: "02314fbb1c30ae5408aa",
  wsHost: 'ws-us2.pusher.com',
  cluster: 'us2',

  //namespace: 'App\Events',
  encrypted: true,
  forceTLS: false,
  /*wsPort: 6001,
  wssPort: 6001,*/
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  /*auth: {
    headers: {
      authorization: 'Bearer ' + token,
    }
  }*/
});

new Vue({
  router,
  provide: RepositoryProvider,
  store,
  render: (h) => h(App),
}).$mount("#app");
