// Aquí se debe guardar la información del usuario logueado
/**
 * FOR TESTING PURPOSE
 */
export default {
	namespaced: true,
	state: {
		loggedUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
	},
	getters: {
		getLoggedUser(state) {
			return state.loggedUser
		},
	},
	mutations: {
		setUser(state, user) {
			state.loggedUser = user
		},
	},
	actions: {
		loadUser({ commit }, payload) {
			const user = {
				config: payload.config,
				customer_id: payload.customer_id,
				email: payload.email,
				name: payload.name,
				profile_id: payload.profile_id,
				profile_name: payload.profile_name ? payload.profile_name : null,
			}

			localStorage.setItem('user', JSON.stringify(user))
			commit('setUser', user)
		},
	},
}
