<template>
  <b-container fluid class="container__login">
    <div class="login__content">
      <h2 class="title__login">Inicio de sesión</h2>
      <div class="panel__msg--error d-flex justify-content-between" v-if="statusMsgError">
        <small class="msg__error mt-1">{{ msgError }}</small>
        <b-button class="btn btn__close--panel" @click="closedPanel">
          <b-icon-x />
        </b-button>
      </div>
      <b-form @submit.prevent="login">
        <div id="usernama">
          <div class="input__field">
            <span class="icono__input">
              <b-icon-person-fill />
            </span>
            <input
              type="email"
              placeholder="john@example.com"
              v-model="user.username"
              autofocus
            />
          </div>
        </div>
        <div id="password">
          <div class="input__field">
            <span class="icono__input">
              <b-icon-lock-fill />
            </span>
            <input
              type="password"
              placeholder="Contraseña"
              v-model="user.password"
            />
          </div>
        </div>
        <div class="olvidar__password">
          <a class="a__login">¿Olvidaste tú contraseña?</a>
        </div>
        <b-button
          type="submit"
          class="btn__ingresar mt-1"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner small v-if="isBusy" />
          Ingresar
        </b-button>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import {
  BIconPersonFill,
  BIconLockFill,
  BIconX,
  BButton,
  BContainer,
  BForm,
  BSpinner,
} from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex"

export default {
  inject: ['authRepository'],
  name: "Login",

  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      statusMsgError: false,
      msgError: "",
      isBusy: false,
    };
  },

  components: {
    BIconPersonFill,
    BIconLockFill,
    BIconX,
    BButton,
    BContainer,
    BForm,
    BSpinner,
  },

  validations: {
    user: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },

  methods: {
    ...mapActions("user", ["loadUser"]),
    async login() {
      const me = this;
      try{
        me.isBusy = true;
        let res = await me.authRepository.login(me.user);
        const { data } = res
        me.statusMsgError = false;
        me.loadUser(data);
        localStorage.setItem("accessToken", data.access_token);
        localStorage.setItem("authenticated", true);
        me.$router.push('/configuracion-de-dashboard');
      }catch(error){
        const { message } = error.data
        if (message == "User not registered") {
          me.msgError = "Usuario no registrado."
        } else {
          me.msgError = "Usuario ó contraseña incorrectos."
        }
        me.statusMsgError = true
      } finally {
        me.isBusy = false
      }
    },
    closedPanel() {
      const me = this;
      me.statusMsgError = false;
    },
  },
};
</script>

<style>
@import "./login.css";
</style>
