export default {
  getAll() {},
  getAllEnterpriseByAdmin() {},
  find(id, params) {},
  save(data) {},
  delete(id) {},
  update(id, data) {},
  login() {},
  register() {},
  getAllBrandByClient() {},
  getAllServicesByBrand() {},
  getAllAreaByService() {},
  getAllSubareaByArea() {},
  getAllLocationPatient() {},
  getAllByDate() {},
  getAllExamByAreaDate() {},
  altaInfo() {},
  altaFisico() {},
};