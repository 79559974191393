import store from "../store";

export default {
  namespaced: true,
  state: {
    layout: [],
    devices: [],
    called: [],
    allCalls: [],
    callBlue: [],
    callOthers: [],
    colNum: 12,
    counter: 0,
    isEdit: false,
    calledOne: {
      called: null,
      timestamp: null,
      bateria: null,
      mac: null,
    },
    timeout: null,
  },

  mutations: {
    addValueToDevice(state, layout) {

      layout.forEach((el) => {
        state.devices.forEach((dev) => {
          if (el.device.mac == dev.mac) {
            dev.sensor.forEach((sen) => {
              if (el.device.type == sen.type) {
                el.device.primarySensor = sen;
              } else if (sen.type == 10) {
                el.device.sensorEnergia = sen;
              } else if (sen.type == 100) {
                el.device.sensorBateria = sen;
              }
            });
            el.device.timestamp = dev.timestamp;
          }
        });

        if (el.device.type == "16") {
          el.device.calls = [];
        }
      });
      state.layout = layout;
      localStorage.setItem("layout", JSON.stringify(state.layout));
    },
    setRemoveDevice(state, index) {
      state.layout.splice(index, 1);
    },
    setEditDash(state) {
      state.layout.forEach((el) => {
        el.isDraggable = true;
        el.isResizable = true;
      });
      state.isEdit = true;
    },
    setSaveDash(state) {
      state.layout.forEach((el) => {
        el.isDraggable = false;
        el.isResizable = false;
      });
      state.isEdit = false;
      localStorage.setItem("layout", JSON.stringify(state.layout));
    },
    setCalls(state, message) {
      const mac = message.mac;
      const timestamp = message.timestamp;

      if (message.llamado) {
        const nameCall = message.llamado;
        if (message.area == message.areaLoca && nameCall !== "Alta") {

          if (nameCall == "Cancelar") {
            state.called.forEach((el, index) => {
              if (el.mac === mac) {
                state.called.splice(index, 1);
              }
            });
          } else {
            const device = state.called.find((el) => el.mac === mac);
            if (device) {
              state.called.forEach((el) => {
                if (el.mac === device.mac) {
                  const call = el.calls.find(
                    (x) => x.name === nameCall && x.mac === device.mac
                  );

                  if (!call) {
                    el.calls.push({
                      name: nameCall,
                      timestamp: timestamp,
                      sensorBateria: message.sensor[0],
                      mac: mac,
                      patient: message.patient,
                      sensor: message.sensor,
                    });
                  }
                }
              });
            } else {
              state.called.push({
                mac: mac,
                calls: [
                  {
                    name: nameCall,
                    timestamp: timestamp,
                    sensorBateria: message.sensor[0],
                    mac: mac,
                    patient: message.patient,
                    sensor: message.sensor,
                  },
                ],
              });
            }
          }

          state.layout.forEach((el) => {
            if (el.device.type === "16") el.device.calls = state.called;
          });
          store.dispatch("device/loadAllCalls");
          store.dispatch("device/renderCalled");
        }
      } else {
        const device = state.devices.find((el) => el.mac == mac);
        if (device) {
          state.devices.forEach((el) => {
            if (el.mac == device.mac) {
              el.timestamp = timestamp;
              el.sensor = message.sensor;
              el.pcbname = message.pcbname;
            }
          });
        } else {
          state.devices.push(message);
        }

        state.layout.forEach((el) => {
          state.devices.forEach((dev) => {
            if (el.device.mac == dev.mac && dev.sensor) {
              dev.sensor.forEach((sen) => {
                if (el.device.type == sen.type) {
                  el.device.primarySensor = sen;
                } else if (sen.type == 10) {
                  el.device.sensorEnergia = sen;
                } else if (sen.type == 100) {
                  el.device.sensorBateria = sen;
                }
              });

              el.device.timestamp = dev.timestamp;
              el.device.sensor = dev.pcbname;
            }
          });
        });

        localStorage.setItem("layout", JSON.stringify(state.layout));
      }
    },
    setloadAllCalls(state) {
      state.allCalls = [];
      state.called.forEach((el) => {
        for (let index = el.calls.length - 1; index >= 0; index--) {
          state.allCalls.push(el.calls[index]);
        }
      });
    },
    setRenderCall(state) {
      clearTimeout(state.timeout);
      state.callBlue = [];
      state.callOthers = [];
      state.called.forEach((el) => {
        for (let index = el.calls.length - 1; index >= 0; index--) {
          if (el.calls[index].name == "Azul") {
            state.callBlue.push(el.calls[index]);
          } else {
            state.callOthers.push(el.calls[index]);
          }
        }
      });

      store.dispatch("device/fillCalled");
    },
    setFillCalled(state) {
      state.counter++;
      if (state.callBlue.length > 0) {
        if (state.counter > state.callBlue.length) state.counter = 1;

        store.dispatch("device/loadCalled", state.callBlue[state.counter - 1]);

        state.timeout = setTimeout(() => {
          store.dispatch("device/fillCalled");
        }, 5000);
      } else {
        if (state.callOthers.length > 0) {
          if (state.counter > state.callOthers.length) state.counter = 1;

          store.dispatch(
            "device/loadCalled",
            state.callOthers[state.counter - 1]
          );

          state.timeout = setTimeout(() => {
            store.dispatch("device/fillCalled");
          }, 5000);
        }
      }
    },
    setloadCalled(state, call) {
      state.calledOne = {
        called: call.name,
        timestamp: call.timestamp,
        bateria: call.sensorBateria.values[0],
        mac: call.mac,
        patient: call.patient,
        sensor: call.sensor,
      };
    },
  },

  actions: {
    addDevice({ commit, state }, payload) {
      if (payload.type == "16") {
        state.layout.push({
          x: (state.layout.length * 2) % (state.colNum || 12),
          y: state.layout.length + (state.colNum || 12),
          w: 8,
          h: 9,
          i: payload.id,
          device: payload,
          isDraggable: false,
          isResizable: false,
        });
      } else {
        state.layout.push({
          x: (state.layout.length * 2) % (state.colNum || 12),
          y: state.layout.length + (state.colNum || 12),
          w: 2,
          h: 2.9,
          i: payload.id,
          device: payload,
          isDraggable: false,
          isResizable: false,
        });
      }

      commit("addValueToDevice", state.layout);
    },
    removeDevice({ commit }, index) {
      commit("setRemoveDevice", index);
    },
    editDash({ commit }) {
      commit("setEditDash");
    },
    saveDash({ commit }) {
      commit("setSaveDash");
    },
    calls({ commit }, payload) {
      commit("setCalls", payload);
    },
    loadDevices({ commit }, payload) {
      commit("addValueToDevice", payload);
    },
    loadAllCalls({ commit }) {
      commit("setloadAllCalls");
    },
    renderCalled({ commit }) {
      commit("setRenderCall");
    },
    fillCalled({ commit }) {
      commit("setFillCalled");
    },
    loadCalled({ commit }, payload) {
      commit("setloadCalled", payload);
    },
  },
};
