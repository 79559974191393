import store from "../store";

export default {
  namespaced: true,
  state: {
    layout: [],
    devices: [],
    called: [],
    allCalls: [],
    callBlue: [],
    callOthers: [],
    calledOne: {
      called: null,
      timestamp: null,
      bateria: null,
      mac: null,
    },
    timeout: null,
    counter: 0,
  },

  mutations: {
    setCalls(state, message) {
      const mac = message.mac;
      const timestamp = message.timestamp;

      if (message.llamado) {
        const nameCall = message.llamado;
        if (message.area == message.areaLoca && nameCall !== "Alta") {

          if (nameCall == "Cancelar") {
            state.called.forEach((el, index) => {
              if (el.mac === mac) {
                state.called.splice(index, 1);
              }
            });
          } else {
            const device = state.called.find((el) => el.mac === mac);
            if (device) {
              state.called.forEach((el) => {
                if (el.mac === device.mac) {
                  const call = el.calls.find(
                    (x) => x.name === nameCall && x.mac === device.mac
                  );

                  if (!call) {
                    el.calls.push({
                      name: nameCall,
                      timestamp: timestamp,
                      sensorBateria: message.sensor[0],
                      mac: mac,
                      patient: message.patient,
                      sensor: message.sensor,
                    });
                  }
                }
              });
            } else {
              state.called.push({
                mac: mac,
                calls: [
                  {
                    name: nameCall,
                    timestamp: timestamp,
                    sensorBateria: message.sensor[0],
                    mac: mac,
                    patient: message.patient,
                    sensor: message.sensor,
                  },
                ],
              });
            }
          }

          store.dispatch("patient/loadAllCalls");
          store.dispatch("patient/renderCalled");
        }
      } 
    },
    setloadAllCalls(state) {
      state.allCalls = [];
      state.called.forEach((el) => {
        for (let index = el.calls.length - 1; index >= 0; index--) {
          state.allCalls.push(el.calls[index]);
        }
      });
    },
    setRenderCall(state) {
      clearTimeout(state.timeout);
      state.callBlue = [];
      state.callOthers = [];
      state.called.forEach((el) => {
        for (let index = el.calls.length - 1; index >= 0; index--) {
          if (el.calls[index].name == "Azul") {
            state.callBlue.push(el.calls[index]);
          } else {
            state.callOthers.push(el.calls[index]);
          }
        }
      });

      store.dispatch("patient/fillCalled");
    },
    setFillCalled(state) {
      state.counter++;
      if (state.callBlue.length > 0) {
        if (state.counter > state.callBlue.length) state.counter = 1;

        store.dispatch("patient/loadCalled", state.callBlue[state.counter - 1]);

        state.timeout = setTimeout(() => {
          store.dispatch("patient/fillCalled");
        }, 5000);
      } else {
        if (state.callOthers.length > 0) {
          if (state.counter > state.callOthers.length) state.counter = 1;

          store.dispatch(
            "patient/loadCalled",
            state.callOthers[state.counter - 1]
          );

          state.timeout = setTimeout(() => {
            store.dispatch("patient/fillCalled");
          }, 5000);
        }
      }
    },
    setloadCalled(state, call) {
      state.calledOne = {
        called: call.name,
        timestamp: call.timestamp,
        bateria: call.sensorBateria.values[0],
        mac: call.mac,
        patient: call.patient,
        sensor: call.sensor,
      };
    },
  },

  actions: {
    calls({ commit }, payload) {
      commit("setCalls", payload);
    },
    loadAllCalls({ commit }) {
      commit("setloadAllCalls");
    },
    renderCalled({ commit }) {
      commit("setRenderCall");
    },
    fillCalled({ commit }) {
      commit("setFillCalled");
    },
    loadCalled({ commit }, payload) {
      commit("setloadCalled", payload);
    },
  },
};
