/* import { loadLiquidFillGauge } from "@/pluggin"; */

export default {
  namespaced: true,
  state:
  {
    messages: [],
  },

  mutations:
  {
    setMessages(state, message){
      state.messages.push(message)
    }
  },
  
  actions:
  {
    loadMessages({commit}, payload){
      commit("setMessages", payload);
    }
  }
};